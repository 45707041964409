/* Home.css */
@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spin-icon {
    animation: spin 2s linear infinite;
  }
  

  /* Prevent horizontal overflow */
html, body {
  overflow-x: hidden;  /* Prevent horizontal scrolling */
}

/* Optional: If you want to ensure the container doesn't exceed the viewport width */
.your-main-container {
  max-width: 100vw; /* Prevents any overflow */
  overflow-x: hidden; /* Just in case */
}
