.header{
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.logo{

    font-family: Verdana, Geneva, Tahoma, sans-serif;
}




.page{
    
     font-family: verdana;
}


/* Hide the menu icon on large screens */
@media (min-width: 1024px) {
    .menu-icon {
      display: none;
    }
  }
  
  /* Show the menu icon on small screens */
  @media (max-width: 1023px) {
    .menu-icon {
      display: block;
    }
  }
 